<template>
  <v-app-bar flat id="nav" >

    <v-btn icon @click="$store.dispatch('toggleDrawer', !drawer)" :disabled="$route.name=='home' || $route.name =='myYoutorials'">
      <v-icon>menu</v-icon>
    </v-btn>
    <a href="/" class="d-flex align-center">
      <img class="logo mr-2" src="@/assets/Youtorial.png">
    </a>
    <!-- <v-toolbar-side-icon></v-toolbar-side-icon> -->
    <!-- <v-toolbar-title>Youtorial</v-toolbar-title> -->
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down d-flex align-center justify-end" >


      <v-btn dark depressed color="gradientBG" class="mr-2" @click="$router.push({name: 'build'})">
        <v-icon class="mr-2">video_library</v-icon>
        Create
      </v-btn>

      <div class="space-tab"> | </div>

      <div class="flex-auto" v-if="isAuthenticated && isVideoPage()">

          <v-btn class="mx-4" color="secondary darken-2" text @click="$router.push({name:'create', params: {videoId: $route.params.videoId}})">
            <v-icon class="mr-1">library_books</v-icon> Edit
          </v-btn>

      </div>

      <!-- if user IS NOT authed -->
      <template v-if="!isAuthenticated">
        <v-btn text large @click="$router.push({name: 'SignUp'})">Sign up</v-btn>
        <v-btn large class="primary" @click="$router.push({name: 'Login'})">Login</v-btn>
      </template>
      <!-- if user IS authed -->
      <template v-else>

        <v-menu
            v-if="!$isEmpty(user)"
            transition="slide-x-transition"
            bottom
            right
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
              <v-avatar size="40" :style="`background-color:${user.bg}`" v-if="user.avatar != undefined">
                <img :src="user.avatar" alt="my photo"/>
              </v-avatar>
              <v-avatar size="40" color="primary" v-else>
                <img v-if="user.photoURL"
                  :src="user.photoURL"
                  alt="my photo"/>
                <span v-else>{{user.email.charAt(0) || 'ME'}}</span>
                  <!-- <span v-else-if="user.name != undefined">{{user.name.charAt(0)}}</span> -->
              </v-avatar>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="$router.push({name: 'profile'}).catch(e=>{})" :class="{'active primary--text':$route.name == 'profile'}">
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push({name: 'myYoutorials'}).catch(e=>{})" :class="{'active primary--text':$route.name == 'myYoutorials'}">
                <v-list-item-title>My Youtorials</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logOut">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
      </template>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "mainNav",
  computed:{
    isAuthenticated: function (){ return this.$store.state.userId},
    ...mapGetters({
      user:'GET_USER',
      userId: "GET_USER_ID",
      drawer: 'GET_DRAWER',
      currentVideo: 'GET_CURRENT_VIDEO',
      buildVideo: 'GET_BUILD_VIDEO'
    }),
  },
  data(){
    return{

    }
  },
  methods:{
    logOut(){
      this.$store.dispatch('logout');
      this.$router.replace('home')
    },
    isVideoPage(){
      if(this.isAuthenticated && this.user){
        if(!this.$isEmpty(this.buildVideo)){
          return this.buildVideo.userId == this.userId ?  true : false;
        }
        else if(!this.$isEmpty(this.currentVideo)){

          return this.currentVideo.userId == this.userId ?  true : false;
        }
        else{
          return false
        }
      }
    },
  }
};
</script>

<style lang="scss">
  #nav{
    width: 100%;
    z-index: 9999;
    border-bottom: 1px solid #e6e6e6;
    position: absolute;
    top:0;
    left: 0;
    .v-toolbar__items{
      .v-btn{height: 44px!important; border-radius: 6px;}
    }
    .logo{
      max-width:150px;
    }
    .space-tab{
      color: #ccc;

      margin: 0 15px;
    }
  }
</style>
